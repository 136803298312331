import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ChatIcon from "@mui/icons-material/Chat";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { formatDate } from "../../helpers/formatDate";

export const TabelaHistorias = ({
  histories,
  onEdit,
  onChatWithHistory,
  onViewHistoryDetails,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    titulo: '',
    status: '',
    responsavel: '',
    prioridade: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState(null);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleFilterClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setFilterColumn(column);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setFilterColumn(null);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [filterColumn]: event.target.value });
  };

  const sortedHistories = React.useMemo(() => {
    let sortableItems = [...histories];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [histories, sortConfig]);

  const filteredHistories = sortedHistories.filter(history => 
    history.titulo.toLowerCase().includes(filters.titulo.toLowerCase()) &&
    history.status.toLowerCase().includes(filters.status.toLowerCase()) &&
    history.responsavel.toLowerCase().includes(filters.responsavel.toLowerCase()) &&
    history.prioridade.toLowerCase().includes(filters.prioridade.toLowerCase())
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Título
              <IconButton size="small" onClick={(e) => handleFilterClick(e, 'titulo')}>
                <FilterListIcon />
              </IconButton>
              <IconButton size="small" onClick={() => handleSort('titulo')}>
                {sortConfig.key === 'titulo' ? (
                  sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                ) : null}
              </IconButton>
            </TableCell>
            <TableCell>
              Status
              <IconButton size="small" onClick={(e) => handleFilterClick(e, 'status')}>
                <FilterListIcon />
              </IconButton>
              <IconButton size="small" onClick={() => handleSort('status')}>
                {sortConfig.key === 'status' ? (
                  sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                ) : null}
              </IconButton>
            </TableCell>
            <TableCell>
              Responsável
              <IconButton size="small" onClick={(e) => handleFilterClick(e, 'responsavel')}>
                <FilterListIcon />
              </IconButton>
              <IconButton size="small" onClick={() => handleSort('responsavel')}>
                {sortConfig.key === 'responsavel' ? (
                  sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                ) : null}
              </IconButton>
            </TableCell>
            <TableCell>
              Prioridade
              <IconButton size="small" onClick={(e) => handleFilterClick(e, 'prioridade')}>
                <FilterListIcon />
              </IconButton>
              <IconButton size="small" onClick={() => handleSort('prioridade')}>
                {sortConfig.key === 'prioridade' ? (
                  sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                ) : null}
              </IconButton>
            </TableCell>
            <TableCell>
              Data de Criação
              <IconButton size="small" onClick={() => handleSort('data_criacao')}>
                {sortConfig.key === 'data_criacao' ? (
                  sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                ) : null}
              </IconButton>
            </TableCell>
            <TableCell>Data de Conclusão Estimada</TableCell>
            <TableCell align="center">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredHistories.map((history) => (
            <TableRow key={history?.id_historia}>
              <TableCell>{history?.titulo || "-"}</TableCell>
              <TableCell>{history?.status || "-"}</TableCell>
              <TableCell>{history?.responsavel || "-"}</TableCell>
              <TableCell>{history?.prioridade || "-"}</TableCell>
              <TableCell>
                {history?.data_criacao
                  ? formatDate(new Date(history?.data_criacao))
                  : "-"}
              </TableCell>
              <TableCell>
                {history?.data_conclusao_estimada
                  ? formatDate(new Date(history?.data_conclusao_estimada))
                  : "-"}
              </TableCell>
              <TableCell>
                <Box
                  display="flex"
                  gap="15px"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Tooltip title="Ver mais detalhes">
                    <IconButton onClick={() => onViewHistoryDetails(history)}>
                      <RemoveRedEyeIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar história">
                    <IconButton onClick={() => onEdit(history)}>
                      <CreateIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat com esta história">
                    <IconButton onClick={() => onChatWithHistory(history)}>
                      <ChatIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
      >
        <MenuItem>
          <TextField
            label={`Filtrar por ${filterColumn}`}
            value={filters[filterColumn] || ''}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
        </MenuItem>
      </Menu>
    </TableContainer>
  );
};
