import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Paper, List, ListItem, Avatar, Box, ListItemText, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as AliceIcon } from '../assets/icons/AliceIcon.svg';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import IAResponse from '../components/IAResponse';  // Importa o novo componente

// Função para contar tokens (mais precisa)
const countTokens = (text) => {
    return text.split(/\s+/).length;
};

// Função para limitar o contexto a 8000 tokens
const limitContextSize = (context, maxTokens = 8000) => {
    let totalTokens = 0;
    const limitedContext = [];
  
    for (let i = context.length - 1; i >= 0; i--) {
      const messageTokens = countTokens(context[i].text);
      totalTokens += messageTokens;
  
      if (totalTokens > maxTokens) break;
  
      limitedContext.unshift(context[i]);
    }
  
    return limitedContext;
  };

const ChatContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  padding: '1rem',
  width: '100%', // Alterado para 100% para ocupar toda a largura da tela
});

const MessageList = styled(List)({
  width: '100%', // Ocupa toda a largura disponível
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 1rem',
});

const MessageItem = styled(ListItem)(({ sender }) => ({
    backgroundColor: sender === 'user' ? '#e0f7fa' : '#f1f1f1',
    borderRadius: '10px',
    marginBottom: '0.5rem',
    padding: '0.5rem',
    alignSelf: sender === 'user' ? 'flex-end' : 'flex-start',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '95%', // Aumenta a largura máxima do elemento para 95% da largura do contêiner
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap', // Garante que o texto respeite as quebras de linha
    margin: '0.2rem',
}));

const AvatarStyled = styled(Avatar)({
    marginRight: '1rem', // Adiciona um espaço à direita do ícone
    alignSelf: 'center', // Centraliza verticalmente o avatar
});

const MessageText = styled(ListItemText)({
    alignSelf: 'center', // Centraliza verticalmente o texto
    whiteSpace: 'pre-wrap', // Garante que o texto respeite as quebras de linha
    wordBreak: 'break-word',
});

const IAResponseContainer = styled(Box)({
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const InputContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '1rem',
  boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
  flexShrink: 0,
});

const AliceCodigo = ({ title = "Assistente de Código" }) => {  // Define um título padrão
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);  // Estado para controlar o carregamento
  const messagesEndRef = useRef(null);  // Cria uma referência para o final da lista de mensagens
  const navigate = useNavigate();  // Hook do react-router-dom para navegação

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();  // Rolagem automática após cada atualização de mensagens
  }, [messages]);

  const handleSendMessage = async () => {
    if (input.trim() !== '') {
        const userMessage = { sender: 'user', text: input };
        const updatedMessages = [...messages, userMessage];
        
        // Limita o contexto a 8000 tokens antes de enviar ao backend
        const limitedContext = limitContextSize(updatedMessages);

        console.log('Contexto enviado para o backend:', limitedContext);
        setMessages(limitedContext);
        setInput('');
        setLoading(true);  // Ativa o indicador de carregamento

        try {
            const response = await fetch('/api/v1/chat-codigo-java', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ context: limitedContext }),
            });

            if (response.ok) {
                const data = await response.json();

                const botMessage = { sender: 'bot', text: data.response };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            } else if (response.status === 401) {
                console.error('Usuário não autenticado. Redirecionando para login...');
                navigate('/');
            } else {
                throw new Error('Erro ao enviar mensagem');
            }
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
        } finally {
            setLoading(false);  // Desativa o indicador de carregamento
        }
    }
};


  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <ChatContainer maxWidth="md">
      <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 72px)', width: '100%' }}>
        <MessageList>
          {messages.map((message, index) => (
            <MessageItem key={index} sender={message.sender}>
               <AvatarStyled>
                {message.sender === 'user' ? <UserIcon width={24} height={24} /> : <AliceIcon width={24} height={24} />}
              </AvatarStyled>
              {message.sender === 'user' ? (
                <MessageText primary={message.text} />
              ) : (
                <IAResponseContainer>
                  <IAResponse text={message.text} />
                </IAResponseContainer>
              )}
            </MessageItem>
          ))}
          {loading && (
            <MessageItem sender="bot">
              <AvatarStyled>
                <AliceIcon width={24} height={24} />
              </AvatarStyled>
              <Box display="flex" alignItems="center">
                <CircularProgress size={24} /> {/* Indicador de carregamento animado */}
                <Typography variant="body1" style={{ marginLeft: '0.5rem' }}>Pensando...</Typography>
              </Box>
            </MessageItem>
          )}
          <div ref={messagesEndRef} />  {/* Referência ao final da lista de mensagens */}
        </MessageList>
        <InputContainer>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={3}  // Define a altura inicial
            placeholder="Digite sua mensagem... (Shift + Enter para nova linha)"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}  // Muda para handleKeyPress
          />
          <Button variant="contained" color="primary" onClick={handleSendMessage} style={{ marginLeft: '1rem' }}>
            Enviar
          </Button>
        </InputContainer>
      </Paper>
    </ChatContainer>
  );
};

export default AliceCodigo;
