import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Modal,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
  FormLabel,
  Button,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";

const ModalContent = styled(Box)({
  background: "#FFF",
  width: "600px",
  margin: "auto",
  padding: "20px 40px 60px",
  borderRadius: "4px",
});

const formInitialValues = {
  projectName: "",
  projectCode: "", // opcional
  description: "",
};

const validationSchema = yup.object({
  projectName: yup.string().required("O nome do projeto é obrigatório"),
  projectCode: yup.string().optional(),
  description: yup.string().required("A descrição do projeto é obrigatória"),
});

export const CriarNovoProjeto = ({
  open,
  handleClose,
  setIsLoading,
  refreshProjects,
}) => {
  const createProject = async (values) => {
    setIsLoading(true);
    try {
      const response = await axios.post("/api/v1/insert-project", {
        nome_projeto: values.projectName,
        descricao_projeto: values.description,
        codigo_projeto: values.projectCode || null, // Envia null se estiver vazio
      });

      console.log("response", response);

      if (response.status !== 200) {
        console.log("error");
        toast.error("Erro ao criar projeto. Por favor, tente novamente.");
        throw Error;
      }

      await refreshProjects();
      toast.success("Projeto criado com sucesso!");
      handleClose();
    } catch (error) {
      console.error("Erro ao criar projeto:", error);
      if (error.response) {
        console.error("Detalhes do erro:", error.response.data);
      }
      toast.error("Erro ao criar projeto. Por favor, tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalContent>
        <Typography marginBottom="20px" fontWeight={600} fontSize="24px">
          Criar Projeto
        </Typography>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={createProject}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="15px">
                {/* Nome do Projeto */}
                <FormLabel>Nome do Projeto *</FormLabel>
                <Box width="100%">
                  <TextField
                    sx={{ width: "100%" }}
                    name="projectName"
                    value={values.projectName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.projectName && Boolean(errors.projectName)}
                    helperText={touched.projectName && errors.projectName}
                    fullWidth
                    margin="normal"
                  />
                </Box>

                {/* Código do Projeto */}
                <FormLabel>Código do Projeto</FormLabel>
                <TextField
                  name="projectCode"
                  value={values.projectCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="normal"
                />

                {/* Descrição do Projeto */}
                <FormLabel>Descrição *</FormLabel>
                <Box width="100%">
                  <TextareaAutosize
                    maxRows={2}
                    style={{
                      resize: "none",
                      height: "100px",
                      padding: "10px",
                      width: "100%",
                    }}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  {errors && errors.description && (
                    <Typography color="red">{errors.description}</Typography>
                  )}
                </Box>
              </Box>

              {/* Botão de Enviar */}
              <Box marginTop="15px" display="flex" justifyContent="flex-end">
                <Button type="submit" variant="contained" color="primary">
                  Criar Projeto
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default CriarNovoProjeto;
