import HttpClient from "../../config/httpClient";

export const handleCspConversionResult = async ({
  fileId,
  setConversionResult,
  setStatusMessage,
  setLoading
}) => {
  try {
    const resultResponse = await HttpClient.get(`/api/v1/csp-cobol-result/${fileId}`);
    
    if (resultResponse.ok) {
      const textResult = await resultResponse.text();
      console.log("Resultado da conversão recebido");
      setConversionResult(textResult);
    } else {
      setStatusMessage("Erro ao buscar o resultado da conversão.");
      console.error("Erro ao buscar o resultado da conversão");
    }
  } catch (error) {
    console.error("Erro ao buscar o resultado da conversão:", error);
    setStatusMessage("Ocorreu um erro ao buscar o resultado da conversão.");
  } finally {
    setLoading(false);
  }
};

export const handleBmsConversionResult = async ({
  fileId,
  setConversionResult,
  setStatusMessage,
  setLoading
}) => {
  try {
    const resultResponse = await HttpClient.get(`/api/v1/mapa-bms-result/${fileId}`);
    
    if (resultResponse.ok) {
      const textResult = await resultResponse.text();
      console.log("Resultado da conversão BMS recebido");
      setConversionResult(textResult);
    } else {
      setStatusMessage("Erro ao buscar o resultado da conversão.");
      console.error("Erro ao buscar o resultado da conversão");
    }
  } catch (error) {
    console.error("Erro ao buscar o resultado da conversão:", error);
    setStatusMessage("Ocorreu um erro ao buscar o resultado da conversão.");
  } finally {
    setLoading(false);
  }
};
