import React from "react";
import { toast } from "react-toastify";
import {
  Box,
  Modal,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
  FormLabel,
  Button,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { updateProject } from "../../services/requisitos/updateProject";
import { FullPageLoading } from "../FullPageLoading";

const ModalContent = styled(Box)({
  background: "#FFF",
  width: "600px",
  margin: "auto",
  padding: "20px 40px 60px",
  borderRadius: "4px",
});

const formInitialValues = {
  nome_projeto: "",
  codigo_projeto: "",
  descricao_projeto: "",
};

const validationSchema = yup.object({
  nome_projeto: yup.string().required("O nome do projeto é obrigatório"),
  codigo_projeto: yup.string().optional(),
  descricao_projeto: yup.string().required("A descrição do projeto é obrigatória"),
});

export const ProjectForm = ({
  open,
  handleClose,
  setIsLoading,
  refreshProject,
  initialValues,
  projectId,
}) => {
  const onSubmit = async (values, formHelpers) => {
    formHelpers.setSubmitting(true);

    try {
      if (!projectId) {
        throw new Error("ID do projeto não fornecido");
      }

      console.log(`Atualizando projeto com ID: ${projectId}`);
      await updateProject(projectId, values);
      toast.success("Projeto atualizado com sucesso!");

      if (refreshProject) {
        await refreshProject();
      }

      handleClose();
    } catch (error) {
      console.error("Erro ao atualizar projeto:", error);
      toast.error("Erro ao atualizar projeto. Por favor, tente novamente.");
    } finally {
      formHelpers.setSubmitting(false);
    }
  };

  const handleInitialValues = () => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      return initialValues;
    }
    return formInitialValues;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalContent>
        <Typography marginBottom="20px" fontWeight={600} fontSize="24px">
          Editar Projeto
        </Typography>
        <Formik
          initialValues={handleInitialValues()}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="15px">
                <FullPageLoading isOpen={isSubmitting} />
                <FormLabel>Nome do Projeto</FormLabel>
                <TextField
                  name="nome_projeto"
                  value={values.nome_projeto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nome_projeto && Boolean(errors.nome_projeto)}
                  helperText={touched.nome_projeto && errors.nome_projeto}
                />

                <FormLabel>Código do Projeto</FormLabel>
                <TextField
                  name="codigo_projeto"
                  value={values.codigo_projeto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <FormLabel>Descrição do Projeto</FormLabel>
                <TextareaAutosize
                  name="descricao_projeto"
                  value={values.descricao_projeto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    resize: "none",
                    height: "100px",
                    padding: "10px",
                    width: "100%",
                  }}
                  rows={4}
                />
              </Box>

              <Box marginTop="15px" display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Salvar Alterações
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
