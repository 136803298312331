import React from "react";
import { toast } from "react-toastify";
import {
  Box,
  Modal,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
  FormLabel,
  Button,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { createHistory } from "../../services/requisitos/createHistory";
import { updateHistory } from "../../services/requisitos/updateHistory";
import { useLocation, useParams } from "react-router-dom";
import { FullPageLoading } from "../FullPageLoading";
import CloseIcon from "@mui/icons-material/Close";

const ModalContent = styled(Box)({
  background: "#FFF",
  width: "800px",
  margin: "auto",
  padding: "20px 40px 60px",
  borderRadius: "4px",
});

const formInitialValues = {
  titulo: "",
  descricao: "",
  status: "",
  prioridade: "",
  responsavel: "",
  data_conclusao_estimada: "",
};

const validationSchema = yup.object({
  titulo: yup.string().required("O título é obrigatório"),
  descricao: yup.string().required("A descrição é obrigatória"),
  status: yup.string().required("O status é obrigatório"),
  prioridade: yup.string().required("A prioridade é obrigatória"),
  responsavel: yup.string().optional(),
  data_conclusao_estimada: yup.string().optional(),
});

export const HistoriaForm = ({
  open,
  handleClose,
  setIsLoading,
  refreshHistories,
  initialValues,
  isEdit,
  projectId,
  historyId,
}) => {
  const params = useParams();
  const location = useLocation();

  const statusOptions = [
    { value: "A Fazer", label: "A Fazer" },
    { value: "Em Progresso", label: "Em Progresso" },
    { value: "Concluída", label: "Concluída" },
  ];

  const priorityOptions = [
    { value: "Baixa", label: "Baixa" },
    { value: "Média", label: "Média" },
    { value: "Alta", label: "Alta" },
  ];

  const onSubmit = async (values, formHelpers) => {
    formHelpers.setSubmitting(true);

    try {
      const projectID = projectId || params?.id;

      if (!projectID) {
        throw new Error("ID do projeto não fornecido");
      }

      if (isEdit) {
        if (!historyId) {
          throw new Error("ID da história não fornecido para atualização");
        }
        console.log(`Atualizando história com ID: ${historyId}`);
        const response = await updateHistory(projectID, historyId, values);
        if (location?.state?.selectedHistory) {
          location.state.selectedHistory = response?.updated_story;
        }
        toast.success("História atualizada com sucesso!");
      } else {
        console.log("Criando nova história");
        await createHistory(projectID, values);
        toast.success("História criada com sucesso!");
      }

      if (refreshHistories) {
        await refreshHistories();
      }

      formHelpers.resetForm();
      handleClose();
    } catch (error) {
      console.error(
        `Erro ao ${isEdit ? "atualizar" : "criar"} história:`,
        error
      );
      toast.error(
        `Erro ao ${
          isEdit ? "atualizar" : "criar"
        } história. Por favor, tente novamente.`
      );
    } finally {
      formHelpers.setSubmitting(false);
    }
  };

  const handleInitialValues = () => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      return initialValues;
    }
    return formInitialValues;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalContent>
        <Box display="flex" justifyContent="flex-end">
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </Box>
        <Typography marginBottom="20px" fontWeight={600} fontSize="24px">
          {isEdit ? "Editar" : "Criar"} História
        </Typography>
        <Formik
          initialValues={handleInitialValues()}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="15px">
                <FullPageLoading isOpen={isSubmitting} />
                <FormLabel>Título *</FormLabel>
                <TextField
                  name="titulo"
                  value={values.titulo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.titulo && Boolean(errors.titulo)}
                  helperText={touched.titulo && errors.titulo}
                />
                {console.log("errors", errors)}
                <FormLabel>Responsável</FormLabel>
                <TextField
                  name="responsavel"
                  value={values.responsavel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.responsavel && Boolean(errors.responsavel)}
                  helperText={touched.responsavel && errors.responsavel}
                />

                <Box width="100%" display="flex" gap="20px">
                  <Box flex={1} display="flex" flexDirection="column">
                    <FormLabel>Status *</FormLabel>
                    <Select
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.status && Boolean(errors.status)}
                      helperText={touched.status && errors.status}
                      width="100%"
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>{" "}
                    {touched.status && errors.status && (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.status}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box flex={1} display="flex" flexDirection="column">
                    <FormLabel>Prioridade *</FormLabel>
                    <Select
                      name="prioridade"
                      value={values.prioridade}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.prioridade && Boolean(errors.prioridade)}
                    >
                      {priorityOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.prioridade && errors.prioridade && (
                      <FormHelperText sx={{ color: "red" }}>
                        {errors.prioridade}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box flex={1} display="flex" flexDirection="column">
                    <FormLabel>Data de Conclusão Estimada</FormLabel>
                    <TextField
                      name="data_conclusao_estimada"
                      type="date"
                      value={values.data_conclusao_estimada}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </Box>
                <FormLabel>Descrição *</FormLabel>
                <TextareaAutosize
                  name="descricao"
                  value={values.descricao}
                  onChange={handleChange}
                  error={touched.descricao && Boolean(errors.descricao)}
                  onBlur={handleBlur}
                  style={{
                    resize: "none",
                    height: "100px",
                    padding: "10px",
                    width: "100%",
                    borderColor: touched.descricao && errors.descricao && "red",
                  }}
                  rows={4}
                />
                {touched.descricao && errors.descricao && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.descricao}
                  </FormHelperText>
                )}
              </Box>

              <Box
                marginTop="15px"
                display="flex"
                gap="15px"
                justifyContent="flex-end"
              >
                <Button type="button" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isEdit ? "Salvar Alterações" : "Criar História"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
