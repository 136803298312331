/* eslint-disable no-useless-escape */
import React from "react";
import { Box, Typography, IconButton, Link } from "@mui/material";
import { ContentCopy as CopyIcon } from "@mui/icons-material";

const IAResponse = ({ text }) => {
  // Função para lidar com a cópia do código
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    alert("Código copiado!");
  };

  // Função para detectar URLs e transformá-las em links
  const renderContent = (text) => {
    if (!text) return null;

    // Expressão regular para detectar URLs
    const urlRegex = /(https?:\/\/[^\s\)]+)/g; // Modificado para evitar captura do parêntese final

    // Divida o texto com base nas URLs encontradas
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      // Se a parte corresponder à URL, renderize-a como link
      if (urlRegex.test(part)) {
        return (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordBreak: "break-all" }}
          >
            {part}
          </Link>
        );
      } else if (part.startsWith("```")) {
        // Bloco de código
        const code = part.replace(/```/g, "");
        return (
          <Box
            key={index}
            sx={{
              backgroundColor: "#2E3B4E", // Fundo escuro para blocos de código
              color: "#FFFFFF", // Fonte clara
              padding: "1rem",
              borderRadius: "5px",
              position: "relative",
              marginBottom: "1rem",
              fontFamily: "monospace",
              width: "100%",
              display: "block",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <pre
              style={{
                margin: 0,
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {code}
            </pre>
            <IconButton
              onClick={() => handleCopy(code)}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "#FFFFFF",
              }}
            >
              <CopyIcon />
            </IconButton>
          </Box>
        );
      } else {
        // Texto comum
        const lines = part.split("\n").filter((line) => line.trim() !== "");

        return lines.map((line, idx) => {
          // Formata código inline com crase ``
          const inlineCode = line.split(/(`.*?`)/g).map((subPart, id) =>
            subPart.startsWith("`") && subPart.endsWith("`") ? (
              <Box
                component="span"
                key={id}
                sx={{
                  backgroundColor: "#f5f5f5",
                  color: "#d32f2f",
                  padding: "0.1rem",
                  borderRadius: "3px",
                  fontFamily: "monospace",
                }}
              >
                {subPart.slice(1, -1)}
              </Box>
            ) : (
              subPart
            )
          );

          return (
            <Typography
              key={idx}
              variant="body1"
              paragraph
              sx={{ width: "100%" }}
            >
              {inlineCode}
            </Typography>
          );
        });
      }
    });
  };

  return <div style={{ width: "100%" }}>{renderContent(text)}</div>;
};

export default IAResponse;
