import HttpClient from "../../config/httpClient";

export const getProjectById = async (id) => {
  const response = await HttpClient.get(
    `${process.env.REACT_APP_API_URI}/api/v1/get-project/${id}`
  );

  // console.log("response", response);

  if (response.ok) {
    // Verifica se a resposta foi bem-sucedida (status 200-299)
    const data = await response.json(); // Converte a resposta para JSON
    // console.log("data", data);
    if (data.project) {
      return data.project;
    } else {
      throw new Error("Formato de dados inválido");
    }
  } else {
    throw new Error(`Erro ao listar projetos: ${response.status}`);
  }
};
