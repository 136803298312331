import { Breadcrumbs, Link, Typography } from "@mui/material";

export const RequisitosBreadcrumb = ({ searchType, currentPage, onGoBack }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        underline="hover"
        color="inherit"
        disabled={!searchType}
        sx={{
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.preventDefault();
          onGoBack();
        }}
      >
        Voltar
      </Link>

      <Typography sx={{ color: "text.primary" }}>
        {" "}
        {currentPage
          ? currentPage
          : searchType === "requisitos"
          ? "Requisitos"
          : "Chat"}
      </Typography>
    </Breadcrumbs>
  );
};

export default RequisitosBreadcrumb;
