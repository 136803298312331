import { Autocomplete, Box, Link, TextField, Typography } from "@mui/material";
import CriarNovoProjeto from "./CriarNovoProjeto";
import { useEffect, useState } from "react";
import { listProjects } from "../../services/requisitos/list-projects";
import { FullPageLoading } from "../FullPageLoading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Requisitos = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null); // Novo estado para capturar erros

  const handleProjectSelection = (value) => {
    if (!value)
      return toast.error(
        "Erro ao tentar selecionar um projeto, tente novamente."
      );
    navigate(`/alice-requisitos/projeto/${value.id_projeto}`);
  };

  const fetchProjects = async () => {
    setIsLoading(true);
    setError(null); // Reseta o erro antes de buscar projetos
    try {
      const data = await listProjects();
      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        console.error("Dados de projetos inválidos:", data);
      }
    } catch (error) {
      console.error("Erro ao buscar projetos:", error);
      setError("Erro ao carregar os projetos. Por favor, tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Box>
      <Typography marginBottom="10px" variant="h5" fontSize="24px">
        Selecione o projeto;
      </Typography>

      {/* Exibe uma mensagem de erro caso ocorra */}
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Autocomplete
          disablePortal
          onChange={(_, value) => handleProjectSelection(value)}
          options={projects}
          getOptionLabel={(option) => option.nome_projeto || ""}
          value={(option) => option.id_projeto || ""}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Projetos" />}
          loading={isLoading} // Adiciona indicador de carregamento no Autocomplete
        />
      )}

      <Typography marginTop="10px" align="right" variant="h5" fontSize="18px">
        Não encontrou o projeto?{" "}
        <Link
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
          sx={{ cursor: "pointer" }}
        >
          Crie um novo
        </Link>
      </Typography>

      <CriarNovoProjeto
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        setIsLoading={setIsLoading}
        refreshProjects={fetchProjects}
      />
      <FullPageLoading isOpen={isLoading} />
    </Box>
  );
};

export default Requisitos;
