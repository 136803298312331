import { Box } from "@mui/material";

export const Container = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="15px"
      maxWidth="1200px"
      margin="0 auto"
    >
      {children}
    </Box>
  );
};
