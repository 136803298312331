import React from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
const UploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "1rem",
});

const UploadForm = ({
  onFileSelect,
  selectedFile,
  buttonLabel = "Escolher Outro Arquivo",
}) => {
  return (
    <UploadContainer>
      <Button variant="outlined" component="label">
        {buttonLabel}
        <input type="file" accept=".txt" hidden onChange={onFileSelect} />
      </Button>
      {selectedFile && (
        <Typography variant="body1" style={{ marginTop: "1rem" }}>
          Arquivo selecionado: {selectedFile.name}
        </Typography>
      )}
    </UploadContainer>
  );
};

export default UploadForm;
