import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, CircularProgress } from '@mui/material';
import logo from '../assets/icons/logo_ebix_ia_v1p.png';

const HomePage = ({ isAuthenticated, handleLogin }) => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    // Não redireciona automaticamente, apenas permite ao usuário clicar no botão de login
  }, [isAuthenticated]);

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4}>
        <img src={logo} alt="Alice Logo" style={{ width: '150px', marginBottom: '20px' }} />
        <Typography variant="h4" component="h1" gutterBottom>
         Bem-vindo ao Assistente Inteligente da Ebix
        </Typography>
        <Typography variant="body1" paragraph>
          Ebix IA é sua Assistente de Linguagem Inteligente e Código da Ebix.
          Sou especializada em ajudar com código e correção de vulnerabilidades de segurança.
        </Typography>

        {!isAuthenticated && (
          <Box mt={2} textAlign="center">
            {isRedirecting ? (
              <>
                <CircularProgress />
                <Typography variant="h6" color="primary" gutterBottom>
                  Redirecionando para a página de login...
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h6" color="error" gutterBottom>
                  Usuário não autenticado. Por favor, faça login.
                </Typography>
                <Box mt={2} display="flex" justifyContent="center">
                  <Button variant="contained" color="primary" onClick={() => {
                    setIsRedirecting(true);
                    handleLogin(); // Redireciona para o login do Cognito ao clicar no botão
                  }}>
                    Login
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default HomePage;
