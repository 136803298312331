/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useRef } from "react";
import {
  Container,
  Paper,
  CircularProgress,
  Box,
  Button,
  Typography,
  LinearProgress,
} from "@mui/material";
import UploadForm from "../components/UploadForm";

const ConverterPdfOctane = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [conversionResult, setConversionResult] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [fileStats, setFileStats] = useState({ totalLines: 0, totalBlocks: 0 });
  const progressPercentage = useRef({ currentBlock: 0, percentage: 0 });

  const handleFileSelect = useCallback(async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const fileContent = await file.text();
    const lines = fileContent.split("\n").length;
    const blocks = Math.ceil(lines / 200);
    setFileStats({ totalLines: lines, totalBlocks: blocks });
  }, []);

  const handlePdfConversion = useCallback(async () => {
    if (!selectedFile) return;

    setLoading(true);
    setStatusMessage("Processando... Isso pode levar até 2 minutos.");

    try {
      // const response = await convertPdf(selectedFile);

      if (true) {
        // const fileId = response.headers.get("X-File-Id");
        // console.log(`File ID recebido: ${fileId}`);
        // const completed = await listenToConversionService({
        //   fileId,
        //   fileStats,
        //   setStatusMessage,
        //   setConversionResult,
        //   setLoading,
        //   progressPercentage,
        // });
        // if (!completed) return;
        // await ConversionService({
        //   fileId,
        //   setConversionResult,
        //   setStatusMessage,
        //   setLoading,
        // });
      } else {
        throw new Error("Erro na conversão");
      }
    } catch (error) {
      console.error("Erro na conversão:", error);
      setStatusMessage(
        "Ocorreu um erro durante a conversão. Por favor, tente novamente."
      );
      setLoading(false);
    }
  }, [selectedFile]);

  const handleDownload = useCallback(() => {
    const element = document.createElement("a");
    const file = new Blob([conversionResult], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = selectedFile.name.replace(".txt", "_convertido.txt");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    console.log("Arquivo de conversão baixado");
  }, [conversionResult, selectedFile]);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        <>
          <>
            <UploadForm
              onFileSelect={handleFileSelect}
              selectedFile={selectedFile}
              buttonLabel="Converter PDF de Testes Octane para planilha"
            />
            {selectedFile && (
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="body1">
                  Total de linhas: {fileStats.totalLines}
                </Typography>
                <Typography variant="body1">
                  Dividido em {fileStats.totalBlocks} blocos
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePdfConversion}
                  style={{ marginTop: "1rem" }}
                >
                  Converter
                </Button>
              </Box>
            )}
          </>

          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={2}
            >
              <CircularProgress />
              <Typography variant="body1" style={{ marginTop: "1rem" }}>
                {statusMessage}
              </Typography>
              <Box mt={2} width="100%">
                <LinearProgress
                  variant="determinate"
                  value={progressPercentage?.current?.percentage}
                />
                <Typography
                  variant="h6"
                  style={{ marginTop: "0.5rem", textAlign: "center" }}
                >
                  {`${Math.round(
                    progressPercentage?.current?.percentage
                  )}% concluído`}
                </Typography>
              </Box>
            </Box>
          )}
          {conversionResult && (
            <Box mt={2}>
              <Box mt={2} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownload}
                >
                  Baixar Resultado
                </Button>
              </Box>
            </Box>
          )}
        </>
      </Paper>
    </Container>
  );
};

export default ConverterPdfOctane;
