import HttpClient from "../../config/httpClient";

export const listProjects = async () => {
  const response = await HttpClient.get(
    `${process.env.REACT_APP_API_URI}/api/v1/list-projects`
  );

  if (response.ok) {  // Verifica se a resposta foi bem-sucedida (status 200-299)
    const data = await response.json();  // Converte a resposta para JSON

    if (data && Array.isArray(data.projects)) {
      return data.projects;  // Retorna apenas a lista de projetos
    } else {
      throw new Error("Formato de dados inválido");
    }
  } else {
    throw new Error(`Erro ao listar projetos: ${response.status}`);
  }
};
