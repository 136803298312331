import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChatRequisitos } from "../../components/Requisitos/ChatRequisitos";
import { Requisitos } from "../../components/Requisitos/Requisitos";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import RequisitosBreadcrumb from "../../components/Requisitos/RequisitosBreadcrumb";

// Remova ou comente esta função se não estiver sendo usada
// const countTokens = (text) => {
//   return text.split(/\s+/).length;
// };

// Função para limitar o contexto a 8000 tokens

export const AliceRequisitos = () => {
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const [searchType, setSearchType] = useState(
    location?.state?.searchType || null
  ); // Estado para controlar o tipo de pesquisa
  const [chatInput, setChatInput] = useState("");
  const [loading, setLoading] = useState(false); // Estado para controlar o carregamento
  const messagesEndRef = useRef(null); // Cria uma referência para o final da lista de mensagens
  const navigate = useNavigate(); // Hook do react-router-dom para navegação

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Salva o arquivo selecionado no estado
  };

  const removeFile = () => {
    setFile(null); // Remove o arquivo anexado
  };

  const handleGenerateDocument = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/api/v1/gerar-documento-historia/${location?.state?.projectId}/${location?.state?.selectedHistory?.id_historia}`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const botMessage = {
          sender: "bot",
          text: `Documento gerado com sucesso. Você pode acessá-lo através deste link: ${data.file_path}`,
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } else if (response.status === 401) {
        console.error(
          "Usuário não autenticado. Redirecionando para a HomePage..."
        );
        navigate("/");
      } else {
        throw new Error("Erro ao gerar documento");
      }
    } catch (error) {
      console.error("Erro ao gerar documento:", error);
      const errorMessage = {
        sender: "bot",
        text: "Desculpe, ocorreu um erro ao gerar o documento. Por favor, tente novamente mais tarde.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom(); // Rolagem automática após cada atualização de mensagens
  }, [messages]);

  useEffect(() => {
    if (location?.state?.searchType) {
      setSearchType(location.state.searchType);
    }
  }, [location?.state?.searchType]);

  const handleSendMessage = async () => {
    if (chatInput.trim() !== "" || file) {
      const userMessage = {
        sender: "user",
        text: chatInput,
        file: file?.name || null,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]); // Adiciona a mensagem do usuário à lista de mensagens
      setChatInput("");
      setLoading(true); // Ativa o indicador de carregamento

      // Usar FormData para enviar a mensagem e o arquivo, se houver
      const formData = new FormData();
      formData.append("message", chatInput); // Adiciona a mensagem
      if (file) {
        formData.append("file", file); // Adiciona o arquivo, se houver
      }

      const urlWithHistory = `${process.env.REACT_APP_API_URI}/api/v1/chat-requisitos-historia/${location?.state?.projectId}/${location?.state?.selectedHistory?.id_historia}`;

      try {
        const response = await fetch(
          location?.state?.hasHistoryMemory
            ? urlWithHistory
            : "/api/v1/chat-requisitos",
          {
            method: "POST",
            credentials: "include",
            body: formData, // Envia o FormData
          }
        );

        if (response.ok) {
          const data = await response.json();
          const botMessage = { sender: "bot", text: data.response };
          setMessages((prevMessages) => [...prevMessages, botMessage]); // Adiciona a resposta do bot à lista de mensagens
          scrollToBottom(); // Adicione esta linha para rolar para baixo após a resposta da IA
        } else if (response.status === 401) {
          console.error(
            "Usuário não autenticado. Redirecionando para a HomePage com o botão de login..."
          );
          navigate("/");
        } else {
          throw new Error("Erro ao enviar mensagem");
        }
      } catch (error) {
        console.error("Erro ao enviar mensagem:", error);
      } finally {
        setLoading(false); // Desativa o indicador de carregamento
        setFile(null); // Limpa o arquivo após o envio
      }
    }
  };

  const handleBreadCrumbProps = () => {
    if (location?.state?.redirectedFrom === "historyDetails") {
      return {
        currentPage: `Chat - ${location?.state?.selectedHistory?.titulo}`,
        onGoBack: () => {
          navigate(
            `/alice-requisitos/projeto/${location?.state?.projectId}/detalhes-da-historia`,
            {
              state: {
                projectId: location?.state?.projectId,
                selectedHistory: location?.state?.selectedHistory,
              },
            }
          );
        },
      };
    }

    if (location?.state?.selectedHistory) {
      return {
        currentPage: `Chat - ${location?.state?.selectedHistory?.titulo}`,
        onGoBack: () => {
          navigate(`/alice-requisitos/projeto/${location?.state?.projectId}`);
        },
      };
    }

    return {
      onGoBack: () => setSearchType(null),
    };
  };

  useEffect(() => {
    return () => {
      if (location.state) {
        location.state = {};
      }
    };
  }, [location]);

  return (
    <>
      <Box>
        <Container maxWidth="md">
          <RequisitosBreadcrumb
            searchType={searchType}
            {...handleBreadCrumbProps()}
          />

          {searchType === null && (
            <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
              <Box
                bgcolor="#ffffff"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mb={4}
                >
                  <Typography variant="h6" gutterBottom>
                    Converse sem a Memória de História
                  </Typography>
                  <Typography variant="body1" align="center" paragraph>
                    Esta opção permite que você converse com o Assistente sobre qualquer tópico relacionado a levantamento de requisitos, sem estar vinculado a um projeto ou história específica.
                  </Typography>
                  <Typography variant="body1" align="center" paragraph>
                    A memória da conversa será mantida apenas para o seu nome de usuário, permitindo uma discussão geral sobre requisitos sem contexto específico de projeto.
                  </Typography>
                  <Button
                    variant="outlined"
                    component="label"
                    onClick={() => setSearchType("chat")}
                  >
                    CONVERSE SEM A MEMÓRIA DE HISTÓRIA
                  </Button>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={4}
                >
                  <Typography variant="h6" gutterBottom>
                    Gerencie Projetos e Histórias
                  </Typography>
                  <Typography variant="body1" align="center" paragraph>
                    Esta opção permite que você gerencie projetos e histórias específicas. Você pode criar, visualizar e editar projetos e suas respectivas histórias de usuário.
                  </Typography>
                  <Typography variant="body1" align="center" paragraph>
                    A memória de contexto será mantida para cada história individualmente, permitindo conversas detalhadas e específicas sobre cada requisito do projeto.
                  </Typography>
                  <Button
                    variant="outlined"
                    component="label"
                    onClick={() => setSearchType("requisitos")}
                  >
                    GERENCIE PROJETOS E HISTÓRIAS
                  </Button>
                </Box>
              </Box>
            </Paper>
          )}
          {searchType === "requisitos" && (
            <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
              <Requisitos />{" "}
            </Paper>
          )}
        </Container>
        {searchType === "chat" && (
          <ChatRequisitos
            messages={messages}
            loading={loading}
            chatInput={chatInput}
            setChatInput={setChatInput}
            handleSendMessage={handleSendMessage}
            handleFileChange={handleFileChange}
            file={file}
            removeFile={removeFile}
            handleGenerateDocument={handleGenerateDocument}
            messagesEndRef={messagesEndRef}
          />
        )}
      </Box>
    </>
  );
};
