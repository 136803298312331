import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useCallback, useEffect, useState } from "react";
import { getProjectById } from "../../services/requisitos/get-project-by-id";
import { FullPageLoading } from "../../components/FullPageLoading";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import RequisitosBreadcrumb from "../../components/Requisitos/RequisitosBreadcrumb";
import { getProjectHistories } from "../../services/requisitos/get-project-histories";
import { Historias } from "../../components/Requisitos/Historias";
import { ProjectForm } from "../../components/Requisitos/ProjectForm";

export const ViewProject = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [histories, setHistories] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleGoBack = useCallback(() => {
    return navigate("/alice-requisitos", {
      state: { searchType: "requisitos" },
    });
  }, [navigate]);

  const fetchProject = useCallback(async () => {
    const id = params.id;
    setIsLoading(true);

    try {
      const response = await getProjectById(id);
      console.log("response project", response);
      setProject(response);
    } catch (error) {
      toast.error("Erro ao carregar os projetos. Por favor, tente novamente.");
      return handleGoBack();
    } finally {
      setIsLoading(false);
    }
  }, [handleGoBack, params.id]);

  const fetchHistorias = useCallback(async () => {
    const id = params.id;
    setIsLoading(true);

    try {
      const response = await getProjectHistories(id);
      setHistories(response);
    } catch (error) {
      toast.error("Erro ao carregar as histórias. Por favor, tente novamente.");
      return handleGoBack();
    } finally {
      setIsLoading(false);
    }
  }, [handleGoBack, params.id]);

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleUpdateProject = useCallback(async () => {
    await fetchProject();
    handleCloseEditModal();
  }, [fetchProject]);

  useEffect(() => {
    fetchProject();
    fetchHistorias();
  }, [fetchProject, fetchHistorias]);

  return (
    <Box maxWidth="1200px" margin="0 auto" padding="0 24px">
      <RequisitosBreadcrumb
        onGoBack={handleGoBack}
        searchType="requisitos"
        currentPage={`Requisitos ${project?.nome_projeto}`}
      />
      <Box
        marginTop="30px"
        sx={{
          border: "solid 1px #ccc",
          padding: "20px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Editar projeto">
            <IconButton onClick={handleOpenEditModal} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display="flex" flexDirection="column" gap="10px">
          <Box display="flex" gap="15px" alignItems="center">
            <Typography fontWeight={600} fontSize="18px">
              Nome do Projeto:
            </Typography>
            <Typography fontSize="18px">{project?.nome_projeto}</Typography>
          </Box>

          {project?.codigo_projeto && (
            <Box display="flex" gap="15px" alignItems="center">
              <Typography fontWeight={600} fontSize="18px">
                Código do Projeto:
              </Typography>
              <Typography flex={1} fontSize="18px">
                {project?.codigo_projeto}
              </Typography>
            </Box>
          )}

          <Box display="flex" gap="15px" alignItems="center">
            <Typography fontWeight={600} fontSize="18px">
              Descrição:
            </Typography>
            <Typography fontSize="18px">
              {project?.descricao_projeto}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box marginTop="20px">
        <Historias histories={histories} refreshHistories={fetchHistorias} />
      </Box>
      <ProjectForm
        open={isEditModalOpen}
        handleClose={handleCloseEditModal}
        setIsLoading={setIsLoading}
        refreshProject={handleUpdateProject}
        initialValues={project}
        projectId={params.id}
      />
      <FullPageLoading isOpen={isLoading} />
    </Box>
  );
};
