import HttpClient from "../../config/httpClient";

export const getProjectHistories = async (id) => {
  const response = await HttpClient.get(
    `${process.env.REACT_APP_API_URI}/api/v1/list-project-stories/${id}`
  );

  console.log("response histories", response);

  if (response.ok) {
    // Verifica se a resposta foi bem-sucedida (status 200-299)
    const data = await response.json(); // Converte a resposta para JSON

    if (data?.stories) {
      return data.stories;
    } else {
      throw new Error("Erro ao listar histórias");
    }
  } else {
    throw new Error(`Erro ao listar histórias: ${response.status}`);
  }
};
