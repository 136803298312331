/**
 * Author: Elisson Sayago Dias (sayago@gmail.com)
 * Date: 2024-08-05
 * Description: Configuração do caminho dos arquivos e pastas para processamento
 */


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography, Container, Grid } from '@mui/material';

const PathsConfig = () => {
  const [config, setConfig] = useState({
    app_path_corrigido: '',
    diretorio_base_fortify: '',
    diretorio_base_output: '',
    diretorio_base_instructions: '',
    diretorio_base_ritm: '',
    nome_branch: '',
    nome_branch_ritm: '',
    nome_pasta: '',
  });

  const [originalConfig, setOriginalConfig] = useState({ ...config });

  useEffect(() => {
    // Fetch existing config on component mount
    axios.get('http://127.0.0.1:8000/api/v1/config-paths')
      .then(response => {
        console.log('GET Response:', response.data);
        setConfig(response.data);
        setOriginalConfig(response.data);
      })
      .catch(error => {
        console.error('GET Error:', error);
        if (error.response && error.response.status === 404) {
          console.log("Configuração não encontrada");
        } else {
          console.error("Erro ao buscar a configuração", error);
        }
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting config:', config);
    axios.post('http://127.0.0.1:8000/api/v1/config-paths', config)
      .then(response => {
        console.log('POST Response:', response.data);
        setConfig(response.data);
        setOriginalConfig(response.data);
        alert("Configuração salva com sucesso!");
      })
      .catch(error => {
        console.error('POST Error:', error);
        console.error("Erro ao salvar a configuração", error);
      });
  };

  const handleCancel = () => {
    // Reset the form to the original configuration
    setConfig(originalConfig);
    alert("Alterações canceladas");
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Configuração de Paths de Diretórios
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="App Path Corrigido"
                name="app_path_corrigido"
                value={config.app_path_corrigido}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Diretório Base Fortify"
                name="diretorio_base_fortify"
                value={config.diretorio_base_fortify}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Diretório Base Output"
                name="diretorio_base_output"
                value={config.diretorio_base_output}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Diretório Base Instructions"
                name="diretorio_base_instructions"
                value={config.diretorio_base_instructions}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Diretório Base RITM"
                name="diretorio_base_ritm"
                value={config.diretorio_base_ritm}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome da Branch"
                name="nome_branch"
                value={config.nome_branch}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome da Branch RITM"
                name="nome_branch_ritm"
                value={config.nome_branch_ritm}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome da Pasta"
                name="nome_pasta"
                value={config.nome_pasta}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button type="submit" variant="contained" color="primary" sx={{ mr: 2 }}>
                Salvar
              </Button>
              <Button type="button" variant="outlined" color="secondary" onClick={handleCancel}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default PathsConfig;
